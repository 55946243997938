import { StatusCode } from '@/modules/asset/constants';
import { computed, Ref } from '@vue/composition-api';

export function useAssetStatus(status: Ref<StatusCode | undefined>) {
    const label = computed(() => {
        switch (status.value) {
            case StatusCode.Available:
                return 'Available';
            default:
                return status.value;
        }
    });

    const statusClass = {
        [StatusCode.Incomplete]: 'text-yellow-700 bg-yellow-100',
        [StatusCode.Uploading]: 'text-purple-700 bg-purple-100',
        [StatusCode.Updating]: 'text-blue-700 bg-blue-100',
        [StatusCode.Deleted]: 'text-red-700 bg-red-100',
        [StatusCode.Available]: 'text-green-700 bg-green-100',
        [StatusCode.Archived]: 'text-neutral-700 bg-neutral-100', // for model assets
    };

    const colour = computed(() => (status.value ? statusClass[status.value] : 'text-yellow-700 bg-yellow-100'));

    const icon = computed(() => null);

    const iconColour = computed(() => {
        switch (status.value) {
            case StatusCode.Incomplete:
                return 'text-yellow-700';
            case StatusCode.Uploading:
                return 'text-purple-700';
            case StatusCode.Updating:
                return 'text-blue-700';
            case StatusCode.Deleted:
                return 'text-red-700';
            case StatusCode.Available:
            default:
                return 'text-yellow-700';
        }
    });

    return { label, colour, icon, iconColour, statusClass };
}
